<template>
    <div>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                <div class="alert alert-info" v-if="info_message" v-html="info_message"></div>
                <b-row>
                    <b-col>
                        <validated-input alternative name="Category Name"
                                         :rules="{required: true}"
                                         label="Category Name" :disabled="loading"
                                         v-model="model.category_name"></validated-input>
                    </b-col>
                    <b-col>
                        <label class="form-control-label">Icon</label>
                        <b-form-file :disabled="loading" :rules="{required: true}" alternative
                                     class="mb-3" id="Icon" :state="Boolean(model.icon)"
                                     label="Icon" name="Icon"
                                     v-model="model.icon"></b-form-file>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group class="form-control-label" label="Is Subcategory">
                            <base-checkbox :disabled="loading" :rules="{required: true}" alternative
                                           class="mb-3"
                                           label="Is Subcategory" name="Is Subcategory"
                                           v-model="model.is_subcategory"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <validated-vue-select alternative v-if="model.is_subcategory" name="Sub Category Parent"
                                         label="Sub Category Parent" :disabled="loading" :options-u-r-l="ParentOptions"
                                         v-model="model.subcategory_parent"></validated-vue-select>
                    </b-col>
                </b-row>

                <base-button type="primary" native-type="submit"
                             :disabled="loading">
                                        <span v-show="loading">
                                            <span>Saving...</span>
                                        </span>
                    <span v-show="!loading">ADD</span>
                </base-button>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'AddCategory',
    data () {
        return {
            model: {
                category_name: '',
                icon: null,
                is_subcategory: false,
                subcategory_parent: ''
            },
            loading: false,
            errorMessage: '',
            info_message: '',
            ParentOptions: urls.admin.category.ParentOptions
        };
    },
    methods: {
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            this.loading = true;
            const component = this;
            axios.form(urls.admin.category.addEdit, component.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Added', 'Category is Added Successfully',
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                    component.$emit('success');
                } else {
                    console.log(json);
                    formValidator.setErrors(json.errors);
                    component.errorMessage = json.errors.__all__ || '';
                    component.$notify('warning', 'Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
                component.loading = false;
            }).catch(function () {
                component.loading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>

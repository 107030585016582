<template>
    <div>
        <div class="container-fluid p4" style="margin-top: 35px">
            <b-card>
                <b-card-title style="margin-bottom: 45px">
                    Category
                    <div class="float-right">
                        <b-btn variant="success" size="md"
                               @click="GotoAdd"> +
                        </b-btn>
                    </div>
                </b-card-title>
                <b-modal id="addCategoryModal" modal-class="modal-right" hide-footer title="Add Category">
                    <AddCategory @success="AddedNew"></AddCategory>
                </b-modal>
                <VueTable ref="table" :url="apiBase" :fields="fields" :perPage="10">
                    <template slot="icon" slot-scope="props">
                        <img :src='prefix + props.rowData.icon'
                             width="96" v-if="props.rowData.icon">
                        <img src="../../../assets/img/logo-white.svg" width="96" v-if="!props.rowData.icon">
                    </template>
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn class="btn btn-info btn-sm" @click="editItem(props.rowData)">
                                Edit
                            </b-btn>
                            <b-btn v-if="props.rowData.disabled" class="btn btn-success btn-sm"
                                   @click="changeStatus(props.rowData.id)">
                                Enable
                            </b-btn>
                            <b-btn v-else class="btn btn-warning btn-sm" @click="changeStatus(props.rowData.id)">
                                Disable
                            </b-btn>
                            <b-btn class="btn btn-danger btn-sm" @click="deleteItem(props.rowData.id)">
                                Delete
                            </b-btn>
                        </div>
                    </template>
                </VueTable>
            </b-card>
            <b-modal id="editCategoryModal" modal-class="modal-right" hide-footer title="Edit Category">
                <EditCategory :model="editingModel" @saved="ItemEdited"></EditCategory>
            </b-modal>
        </div>
    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import VueTable from 'v-tables/src/VueTable';
import AddCategory from '@/views/admin/category/AddCategory';
import EditCategory from '@/views/admin/category/EditCategory';

export default {
    name: 'ListCategory',
    data () {
        return {
            editingModel: {
                id: '',
                category_name: '',
                icon: null,
                is_subcategory: false,
                subcategory_parent: ''
            },
            prefix: urls.baseUrl,
            editingCategory: null,
            apiBase: urls.admin.category.list,
            fields: [
                {
                    name: 'id',
                    sortField: '',
                    title: 'ID',
                    titleClass: ''
                },
                {
                    name: '__slot:icon',
                    sortField: 'icon',
                    titleClass: ''
                },
                {
                    name: 'category_name',
                    sortField: 'category_name',
                    title: 'Category Name',
                    titleClass: ''
                },
                {
                    name: 'is_subcategory',
                    sortField: 'is_subcategory',
                    title: 'Is Subcategory',
                    titleClass: ''
                },
                {
                    name: 'subcategory_parent',
                    sortField: 'subcategory_parent',
                    title: 'Subcategory Parent',
                    titleClass: ''
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'center aligned text-right'
                }
            ]
        };
    },
    methods: {
        GotoAdd () {
            this.$bvModal.show('addCategoryModal');
        },
        AddedNew () {
            this.$bvModal.hide('addCategoryModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.editingCategory = item;
            this.editingModel.id = item.id;
            this.editingModel.category_name = item.category_name;
            this.editingModel.icon = item.icon;
            this.editingModel.is_subcategory = item.is_subcategory;
            this.editingModel.subcategory_parent = item.subcategory_parent;
            this.$bvModal.show('editCategoryModal');
        },
        ItemEdited () {
            this.editingCategory.id = this.editingModel.id;
            this.editingCategory.category_name = this.editingModel.category_name;
            this.editingCategory.icon = this.editingModel.icon;
            this.editingCategory.is_subcategory = this.editingModel.is_subcategory;
            this.editingCategory.subcategory_parent = this.editingModel.subcategory_parent;
            this.$bvModal.hide('editCategoryModal');
            this.editingCategory = null;
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const component = this;
            const submit = confirm('Are you sure to delete..!');
            if (!submit) {
                return;
            }
            axios.form(urls.admin.category.delete, { id: item }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Deleted', 'Category is Deleted Successfully',
                        {
                            duration: 3000,
                            permanent: false,
                            color: 'warning'
                        }
                    );
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        },
        changeStatus (product) {
            if (!confirm('Are you sure?')) {
                return;
            }
            const component = this;

            axios.form(urls.admin.category.disableEnable, {
                id: product
            }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Success', '');
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        }
    },
    components: { AddCategory, EditCategory, VueTable }
};
</script>

<style scoped>

</style>
